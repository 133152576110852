import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { Md5 } from "ts-md5/dist/md5";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Child } from "../models/people/child.class";
import { Logger } from "../providers/logger";

import { AccountService } from "./account.service";
import { BackendCallService } from "./backend-call.service";
import { ChildService } from "./child.service";
import { ConfigService } from "./config.service";

export interface FamilyCard {
  id?: string;
  family_card: string;
}

@Injectable({
  providedIn: "root",
})
export class FamilyCardService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  private fam_card_active: boolean;
  private children: Child[] = [];

  private fam_cards: BehaviorSubject<{ [child_id: number]: FamilyCard }> =
    new BehaviorSubject<{ [child_id: number]: FamilyCard }>({});
  private all_family_cards: { [child_id: number]: FamilyCard } = {};

  constructor(
    private child_service: ChildService,
    private backend: BackendCallService,
    private config_service: ConfigService,
    private acc_service: AccountService
  ) {
    super();
    this.fam_card_active = this.config_service.config.fam_card_active;
    this.init(child_service, backend, acc_service);
  }

  protected onReady(): void {
    if (!this.fam_card_active) {
      return this.set_ready();
    }

    this.subscriptions.push(
      this.child_service.get_children$().subscribe(async (children) => {
        this.children = children;

        await this.load_family_cards();
        for (const child of this.children) {
          const family_card = this.get_family_card(child);

          const valid = await this.check(child, family_card);
          this.set_family_card(child, valid ? family_card : undefined);
        }
      })
    );

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_family_cards$(): Observable<{ [child_id: number]: FamilyCard }> {
    return this.fam_cards.asObservable();
  }
  public get_family_cards(): { [child_id: number]: FamilyCard } {
    return this.fam_cards.getValue();
  }
  public get_family_card(child: Child): FamilyCard {
    return child ? this.get_family_cards()[child.pk] : undefined;
  }

  private set_family_card(child: Child, fam_card: FamilyCard): void {
    const fam_cards = this.get_family_cards();
    fam_cards[child.pk] = fam_card;

    this.fam_cards.next(fam_cards);
  }

  public check(child: Child, family_card: FamilyCard): Promise<boolean> {
    return this.api_check_family_card(child, family_card).catch((err) => {
      Logger.error("error checking family card", {error: err});
      return false;
    });
  }

  public save(child: Child, family_card: FamilyCard): Promise<boolean> {
    if (!child || !family_card) {
      return;
    }

    const api_save = this.all_family_cards[child.pk]?.id
      ? () => this.api_update_family_card(child, family_card)
      : () => this.api_create_family_card(child, family_card);

    return api_save()
      .then(() => {
        this.set_family_card(child, family_card);
        return true;
      })
      .catch(() => false);
  }

  /**
   * load familycard stored in backend and set its validity by year & endpoint.
   */
  private async load_family_cards(): Promise<void> {
    return Promise.allSettled(
      this.children.map((child) => this.load_family_card(child))
    ).then();
  }

  private load_family_card(child: Child) {
    // get family_card
    return this.api_get_family_card(child).then(async (family_card_data) => {
      // none found
      if (!family_card_data) {
        this.set_family_card(child, undefined);
        return;
      }

      this.all_family_cards[child.pk] = family_card_data;

      // content check
      const valid = await this.check(child, family_card_data);
      this.set_family_card(child, valid ? family_card_data : undefined);
    });
  }

  // *************************************
  // **** Backend API functions start ****
  // *************************************

  /**
   * Holt vorhandene Freizeitkarte
   * Die Freizeitkarte spielt nur für Wiesbaden eine Rolle
   * Wird eine gültige Freizeitkarte hintelegt, kann das zur Freischaltung einer günstigeren Preiskategorie führen
   * @param child Child
   * @returns object - Freizeitkarten-Objekt
   */
  private api_get_family_card(user: Child): Promise<FamilyCard> {
    const is_child = true;

    const url = `${this.backend.get_backend_domain()}/api/user${
      is_child ? "/child/" + user.pk : ""
    }/familycard`;

    return this.backend.get_with_token<FamilyCard>(url).catch(() => null);
  }

  /**
   * Speichert die angegebene Freizeitkarte unter dem Kind
   * @param child Child
   * @param family_card FamilyCard
   * @returns object - response
   */
  private api_create_family_card(
    child: Child,
    family_card: FamilyCard
  ): Promise<any> {
    if (!child || !family_card || this.all_family_cards[child.pk]) {
      return;
    }

    const is_child = true;
    const url = `${this.backend.get_backend_domain()}/api/user${
      is_child ? "/child/" + child.pk : ""
    }/familycard/create`;

    return this.backend.post_with_token(url, family_card).catch(() => null);
  }

  /**
   * überschreibt die vorhandene Freizeitkarte
   * @param child Child
   * @param family_card FamilyCard
   * @returns object - response
   */
  private api_update_family_card(
    child: Child,
    family_card: FamilyCard
  ): Promise<any> {
    const is_child = true;
    const url = `${this.backend.get_backend_domain()}/api/user${
      is_child ? "/child/" + child.pk : ""
    }/familycard`;

    return this.backend.put_with_token(url, family_card).catch(() => null);
  }

  /**
   * Prüft ob der angegebene Freizeitkartencode korrekt ist
   * Freizeitkarte nur für WI relevant
   * @param child Child
   * @param family_card FamilyCard
   * @returns object - boolean if valid card or not
   */
  private async api_check_family_card(
    child: Child,
    family_card?: FamilyCard
  ): Promise<boolean> {
    family_card = family_card || this.get_family_card(child);
    const email = this.acc_service.get_account()?.email;
    if (!child || !email || !family_card?.family_card) {
      return false;
    }

    const secret = `${email}: ${child.first_name} ${child.last_name}`;
    const hash: string = Md5.hashStr(secret);   // See https://www.npmjs.com/package/ts-md5
    const url = `${this.backend.get_backend_domain()}/api/bonuscard/validate/${hash}/${family_card.family_card}`;

    return this.backend.get_with_token<{valid: boolean}>(url)
      .then(({valid}) => valid)
      .catch(() => false);
  }

  // ***********************************
  // **** Backend API functions end ****
  // ***********************************
}
