import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Reservation } from "../models/booking/reservation.interface";
import {
  FeriproBooking,
  Booking,
  FeriproRegistration,
} from "../models/event/booking.interface";
import { Program } from "../models/program/program.interface";
import { Logger } from "../providers/logger";

import { AuthService } from "./auth.service";
import { BackendCallService } from "./backend-call.service";
import { EnvironmentInfoService } from "./environment-info.service";
import { ProgramService } from "./program.service";
import { ReservationService } from "./reservation.service";
import { TokenService } from "./token.service";

export interface FeriproUuidObject {
  id: number;
  program_id: number;
  token: string;
  child_obj: number;
  other_participant: number;
  status: string;
  modified_date: Date;
}

// ! BOOKED & PAYED have the same string at feripro ('1_YES'), differentiate by balance:
// ! registration.balance >= 0 ? payed : booked;
export enum BookingState {
  BOOKED = "1_YES_booked",
  WAITINGLIST = "2_MAYBE",
  REJECTED = "3_NO",
  UNWORKED = "4_RECEIVED",
  PAYED = "1_YES_payed",
}

@Injectable({
  providedIn: "root",
})
export class BookingService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  private logged_in: boolean;

  private all_uuid_objects: Booking[] = [];
  private uuid_objects: BehaviorSubject<Booking[]> = new BehaviorSubject<
    Booking[]
  >([]);
  private booked_uuid_objects: BehaviorSubject<Booking[]> = new BehaviorSubject<
    Booking[]
  >([]);

  private current_program: Program;

  constructor(
    private backend: BackendCallService,
    private token_service: TokenService,
    private program_service: ProgramService,
    private env_info_service: EnvironmentInfoService,
    private auth: AuthService,
    private reservation_service: ReservationService
  ) {
    super();
    this.init(
      backend,
      token_service,
      program_service,
      env_info_service,
      auth,
      reservation_service
    );
  }

  protected async onReady(): Promise<void> {
    this.subscriptions.push(
      this.auth.is_logged_in$().subscribe((logged_in) => {
        this.logged_in = logged_in;

        if (this.logged_in) {
          return this.get_all_booked_events();
        }
      }),

      this.program_service.get_current_program$().subscribe((program) => {
        this.current_program = program;
        this.set_uuid_objects(this.all_uuid_objects);
      }),

      this.program_service.get_all_programs$().subscribe(() => {
        if (this.logged_in) {
          return this.get_all_booked_events();
        }
      })
    );

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_all_uuid_objects(): Booking[] {
    return this.all_uuid_objects;
  }
  public get_uuid_objects$(): Observable<Booking[]> {
    return this.uuid_objects.asObservable();
  }
  public get_uuid_objects(): Booking[] {
    return this.uuid_objects.getValue();
  }
  public get_booked_uuid_objects$(): Observable<Booking[]> {
    return this.booked_uuid_objects.asObservable();
  }
  public get_booked_uuid_objects(): Booking[] {
    return this.booked_uuid_objects.getValue();
  }
  public get_cancellable_uuid_objects$(): Observable<Booking[]> {
    return this.get_uuid_objects$().pipe(
      map((uuid_objects) => {
        return uuid_objects
          .map((uuid_object) => ({
            ...uuid_object,
            registrations: this.get_cancellable_registrations_of(uuid_object),
          }))
          .filter((uuid_object) => uuid_object.registrations.length);
      })
    );
  }
  public get_cancellable_uuid_objects(): Booking[] {
    return this.get_uuid_objects()
      .map((uuid_object) => ({
        ...uuid_object,
        registrations: this.get_cancellable_registrations_of(uuid_object),
      }))
      .filter((uuid_object) => uuid_object.registrations.length);
  }
  private set_uuid_objects(all_uuid_objects: Booking[]): void {
    this.all_uuid_objects = all_uuid_objects;

    const uuid_objects = this.all_uuid_objects.filter(
      (uuid_object) =>
        uuid_object.program_id === this.current_program.program_id
    );
    this.uuid_objects.next(uuid_objects);
  }

  private get_cancellable_registrations_of(uuid_object: Booking): FeriproRegistration[] {
    return uuid_object.registrations.filter(
      (registration) => registration.storno_allowed
    );
  }

  /**
   * holt alle gebuchten Veranstaltungen von allen Nutzern (die Nutzer, die schon eine uuid bei feripro haben)
   * badges werden aktualisiert
   * used by app compo, event page, registration- & registration-storno page
   */
  public async get_all_booked_events(): Promise<boolean> {
    const booked_uuids = await this.api_get_uuids();

    return Promise.allSettled(
      booked_uuids.map((booked_uuid) => {
        return this.api_get_uuid_bookings(
          booked_uuid.program_id,
          booked_uuid.token
        ).then((booking) => {
          // object of multiple registrations
          if (!booking?.registrations) {
            return undefined;
          }

          // todo einordnung noch notwendig? evtl für anzeige der badges notwendig
          for (const registration of booking.registrations) {
            if (registration.status.startsWith("1_YES")) {
              registration.status =
                registration.balance >= 0
                  ? BookingState.PAYED
                  : BookingState.BOOKED;
            }
          }
          return {
            ...booking,
            program_id: booked_uuid.program_id,
            token: booked_uuid.token,
            child_obj: booked_uuid.child_obj,
          } as Booking;
        });
      })
    )
      .then((bookings) => {
        this.set_uuid_objects(
          bookings
            .filter((b) => b && b.status === "fulfilled" && b.value)
            .map((b: PromiseFulfilledResult<Booking>) => b.value)
        );
        return true;
      })
      .catch(() => false);
  }

  // *************************************
  // **** Backend API functions start ****
  // *************************************

  /**
   * bucht alle vorhanden Anmeldungen bei Feripro
   * vermutlich überflüssig wenn man die observable entfernt
   * used by shopping-cart-question page
   */
  public api_booking_all_participant(
    reservations: Reservation[]
  ): Promise<boolean> {
    return this.api_booking_event(reservations);
  }

  /**
   * führt eine Anmeldung mit bestehenden Daten durch
   * Vorbereitungen und Modifikationen
   * @param reservations alle Registrierungen der Nutzer (warenkorb)
   * ! im detail durchgehen
   */
  private async api_booking_event(
    reservations: Readonly<Reservation>[]
  ): Promise<boolean> {
    let booking_was_successful = true;

    // prepare all reservations that have registrations for booking
    reservations = reservations
      .filter((reservation) => reservation.registrations.length)
      .map((reservation) => {
        // prüfen ob es für das programm die uuid für den Nutzer oder das jeweilige Kind gibt (=> es gab schon eine Buchung)
        // in feripro keine Unterscheidung zwischen Kind / Elternteil /was auch immer -> jeder hat eigene uuid
        // dann füge die Buchung zum bestehenden Nutzer hinzu statt einen neuen Nutzer anzulegen
        // jeder Nutzer hat eine uuid pro programm oder eben noch keine, wenn er noch nicht gebucht hat
        const uuid_object = this.get_uuid_objects().find(
          (uuid_obj) =>
            uuid_obj.child_obj === reservation.cache.child_obj ||
            (!uuid_obj.child_obj &&
              reservation.participant.first_name === uuid_obj.first_name)
        );

        // falls es uuid schon gibt, zuweisen (token entspricht uuid)
        if (uuid_object) {
          this.reservation_service.set_token(reservation, uuid_object.token);
        }

        return reservation;
      });

    const default_error_message =
      "Ein unerwarteter Fehler ist aufgetreten, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";

    // book
    for (const reservation of reservations) {
      // Anfrage pro Nutzer/Kind für mehrere registrations
      await this.api_booking_event_on_data(reservation).then(async (response) => {

        this.reservation_service.set_response_error_message(
          reservation,
          response.submitted ? "" : response.message || default_error_message
        );
        if (!response.submitted) {
          booking_was_successful = false;
        }
      });
    }

    // token muss geändert werden
    await this.token_service.refresh_token();

    // refresh booked events (weil ggf. neue uuids entstanden sind)
    await this.get_all_booked_events();

    return booking_was_successful;
  }

  /**
   * Einzelne Anmeldung bei Feripro
   * Für einen Nutzer alle Veranstaltungen buchen
   * hier wird eine uuid bei feripro für einenn Nutzer angelegt, wenn er noch keine hat
   */
  private api_booking_event_on_data(
    reservation: Reservation
  ): Promise<{ submitted: boolean; message: string }> {
    const url = `${this.backend.get_feripro_backend_domain()}/api/programs/${reservation.cache.program_id}/participant-registration`;

    return this.backend
      .post_with_token<{
        message: string; // includes html tags
        message_plain: string; // same as 'message' without html tags
        submitted: boolean;
        info: {
          rid: number;
          registration_states: BookingState;
          uuid?: string;
          code?: string;
        };
        pass_url: string;
      }>(url, reservation)
      .then(async (val) => {

        if (val.submitted) {
          // uuid nach Buchung bei Feripro wird bei uns im Backend gespeichert
          // Aufruf für jede uuid, ob neu oder nicht, und backend entscheidet, ob neu und somit gespeichert wird
          await this.api_create_uuid(val.info.uuid || val.info.code || val.pass_url.split("/").reverse()[0], reservation);

          // jetzt Anmeldung erfolgreich eingegangen
        }
        return {
          submitted: val.submitted,
          message: val.message || val.message_plain,
        };
      })
      .catch((err) => {
        return { submitted: false, message: err.error.message_plain };
      })
      // wait for 500ms to let feripro add the registration. Otherwise duplicates will occur.
      .finally(() => new Promise<void>((resolve) => setTimeout(() => resolve(), 500)));
  }

  /**
   * get all registrations (uuid relevant informations (siehe uuid_object_array)) of user with this uuid of this program
   * @param program_id id of programm
   * @param uuid of user
   */
  private api_get_uuid_bookings(
    program_id: number,
    uuid: string
  ): Promise<FeriproBooking> {
    const url = `${this.backend.get_feripro_backend_domain()}/api/programs/${program_id}/participants/${uuid}`;

    return this.backend
      .get_with_token<FeriproBooking>(url)
      .catch(() => ({} as any));
  }

  /**
   * storniert eine Anmeldung
   * @param event_id gloabl id of event != rel_id
   */
  public api_remove_registration(
    uuid: string,
    event_id: number
  ): Promise<boolean> {
    const url =
      `${this.backend.get_feripro_backend_domain()}/api/programs/${
        this.current_program.program_id
      }` + `/participants/${uuid}/registrations/${event_id}/storno`;

    return this.backend
      .post_with_token<FeriproBooking>(url, {})
      .then((res) => {
        // delete locally
        const uuid_objects = this.all_uuid_objects.map((uuid_object) => {
          if (
            uuid_object.program_id !== this.current_program.program_id ||
            uuid_object.token !== uuid
          ) {
            return uuid_object;
          }

          return {
            ...uuid_object,
            registrations: uuid_object.registrations.filter(
              (reg) => reg.event_id !== event_id
            ),
          };
        });
        this.set_uuid_objects(uuid_objects);
        return !!res;
      })
      .catch((err) => {
        Logger.error("error removing registration", {error: err});
        // this.messageService.add_error_timeless("Ein unerwarteter Fehler ist aufgetreten.
        // Die Anmeldung konnte nicht storniert werden ..");

        return false;
      });
  }

  /**
   * bestätigt eine Anmeldung
   * Feripro setzt Parameter 'confirmed'
   * used by event page & registration page
   */
  public api_confirm_registration(
    uuid_object: Booking,
    registration: FeriproRegistration
  ): Promise<boolean> {
    const url =
      `${this.backend.get_feripro_backend_domain()}/api/programs/${
        uuid_object.program_id
      }` +
      `/participants/${uuid_object.token}/registrations/${registration.event_id}/confirmation`;

    return this.backend
      .post_with_token<{ is_confirmed: boolean }>(url, { is_confirmed: true })
      .then(() => true)
      .catch(() => false);
  }

  //// ***** communication with feripro end *****

  /**
   * speichert die uuid in der DB
   * pro Nutzer oder Kind
   * @param uuid registration data
   * @param child_id id des Kindes, wenn Kind
   */
  private api_create_uuid(
    uuid: string,
    reservation: Reservation
  ): Promise<boolean> {
    const url = `${this.backend.get_backend_domain()}/api/feriprouuid/`;

    const data = {
      token: uuid,
      program_id: this.current_program.program_id,
      child_obj: reservation.cache.child_obj,
      other_participant: reservation.cache.other_participant,
    };

    type response = {
      id: number,
      modified_date: string,  // ISO-datestring

      token: string,
      program_id: number,
      child_obj: number | undefined,
      other_participant: number | undefined,
    };
    return this.backend.post_with_token<response>(url, data).then(res => !!res);
  }

  /**
   * holt alle gespeicherten uuid (Nutzer) für das Profil
   * anahnd der uuid können dessen Registrierungen nachvollzogen werden (bei feripro)
   */
  private api_get_uuids(): Promise<FeriproUuidObject[]> {
    if (!this.logged_in) { return Promise.resolve([]); }

    const url = `${this.backend.get_backend_domain()}/api/feriprouuid/`;

    return this.backend
      .get_with_token<FeriproUuidObject[]>(url)
      .then((uuids) => uuids || [])
      .catch((err) => {
        Logger.error("error getting feripro-uuids", {error: err});
        return [];
      });
  }

  // ***********************************
  // **** Backend API functions end ****
  // ***********************************
}
