import { Injectable, OnDestroy } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";
import {
  Token,
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
} from "@capacitor/push-notifications";
import { Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Logger } from "../providers/logger";

import { AccountService } from "./account.service";
import { AuthService } from "./auth.service";
import { BackendCallService } from "./backend-call.service";
import { EnvironmentInfoService } from "./environment-info.service";
import { RouterService } from "./router.service";

@Injectable({
  providedIn: "root",
})
export class PushService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  // Parameters saved in zi_backend
  private name = "";
  private device_id = "";
  private is_active: boolean;
  private type = "";

  constructor(
    private account_service: AccountService,
    private auth: AuthService,
    private backend: BackendCallService,
    private env_info_service: EnvironmentInfoService,
    private router_service: RouterService
  ) {
    super();
    this.init(account_service, auth, backend, env_info_service);
  }

  protected onReady(): void {
    Logger.log("pushies are ready")
    
    if (!Capacitor.isPluginAvailable('PushNotifications')) { return this.set_ready(); }


    this.type = this.env_info_service.is_android()
      ? "android"
      : (this.env_info_service.is_ios()
      ? "ios"
      : "");

    this.subscriptions.push(
      this.account_service
        .get_account$()
        .subscribe((account) => (this.name = account?.email)),

      // Register as PushNotification device in zi_backend on login
      this.auth.is_logged_in$().subscribe((logged_in) => {
        if (logged_in && this.env_info_service.is_mobile()) {
          return this.registerPush();
        }
      })
    );

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  // Register and define PushNotification behavior through Ionic Capacitor PushNotification API
  private async registerPush(): Promise<void> {
    Logger.log("[*] Start registerPush");

    await PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    await PushNotifications.addListener("registration", (token: Token) => {
      // Logger.log("My token: " + JSON.stringify(token));
      this.registerDeviceInBackend(token.value);
    });

    await PushNotifications.addListener("registrationError", (error: any) => {
      Logger.error("Error: " + JSON.stringify(error));
      // ToDo Error handling (probably gonna be handled by sentry)
    });

    // Fires when Pushnotification is received in Foreground
    await PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotificationSchema) => {
        Logger.log("Push received: " + JSON.stringify(notification));
        // Show as LocalNotification in statusbar
        return this.scheduleLocalNotification(notification);
      }
    );

    // Fires when Pushnotification is tapped (App closed or killed)
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        Logger.log(
          "Action performed: " + JSON.stringify(notification.notification)
        );

        // Route to message
        if (data.messageId) {
          Logger.log("[*] Routing to message. Message ID: " + data.messageId);
          await this.router_service.navigate(`registrations`);
        }
      }
    );
    Logger.log("[*] End register push");
  }

  ///////////////////////////////////////////
  // API calls to zi_backend

  private async registerDeviceInBackend(
    registration_id: string,
    update_existing_device = false
  ): Promise<any> {
    // POST data to be transmitted to zi_backend.
    const device_data = {
      name: this.name,
      registration_id,
      device_id: this.device_id,
      is_active: this.is_active,
      type: this.type,
    };

    // zi_backend endpoint for registering and updating push devices.
    let url = `${this.backend.get_backend_domain()}/api/pushnotifications/devices/`;

    // Add the registration_token to the url, to update an existing device.
    if (update_existing_device) {
      url += registration_id;
    }

    // Fire request to zi_backend.
    return this.backend
      .post_with_token(url, device_data)
      .catch((err) => Logger.error("Erroro register device with backend", {error: err}));
  }

  // End API calls to zi_backend
  ///////////////////////////////////////////

  ///////////////////////////////////////////
  // Helper functions

  /**
   * Presents a Local Notification
   * Expects a notification of type PushNotification.
   * @todo set this up for data messages, in order to route to a ressource.
   */

  private async scheduleLocalNotification(
    notification: PushNotificationSchema
  ) {
    const notifs = await LocalNotifications.schedule({
      notifications: [
        {
          title: notification.title,
          body: notification.body,
          schedule: { at: new Date(Date.now() + 1000) },
          id: 1, // IDK what this is needed for
          // sound: null,
          // attachments: null,
          // actionTypeId: '',
          // extra: null
        },
      ],
    });
    Logger.log("scheduled notifications", {notifs});
  }

  // End Helper functions
  ///////////////////////////////////////////
}
