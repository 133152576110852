import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { map, filter, tap } from "rxjs/operators";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import {
  Registration,
  Reservation,
} from "../models/booking/reservation.interface";
import { Event } from "../models/event/event.interface";
import { DistributionType } from "../models/program/distribution-type.enum";
import { Program } from "../models/program/program.interface";
import { Question } from "../models/question/custom-question.interface";
import { CheckStep1Service } from "../pages/shopping-cart/prio/check-step1.service";

import { ConfigService } from "./config.service";
import { EventService } from "./event.service";
import { PriceCategoryService } from "./price-category.service";
import { ProgramService } from "./program.service";
import {
  AccountType,
  Person,
  QuestionService
} from "./question.service";
import { ReservationService } from "./reservation.service";

/**
 * Enum (can't use page objects because of circular deps) whose values are urls to referenced BookingPages
 */
export enum BookingStep {
  PRIO = "/shopping-cart/prio/",
  GROUPS = "/shopping-cart/groups/",
  QUESTIONS = "/shopping-cart/questions/",
  PRICE_CATEGORY = "/shopping-cart/price-category/",
  CONFIRM = "/shopping-cart/confirm/",
}

@Injectable({
  providedIn: "root",
})
export class BookingStepsService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  private existing_pages$: BehaviorSubject<BookingStep[]> = new BehaviorSubject<
    BookingStep[]
  >(undefined);
  private allowed_pages$: BehaviorSubject<BookingStep[]> = new BehaviorSubject<
    BookingStep[]
  >(undefined);

  private updater_running: boolean;

  constructor(
    private program_service: ProgramService,
    private config_service: ConfigService,
    private reservation_service: ReservationService,
    private check_1_service: CheckStep1Service,
    private price_category_service: PriceCategoryService,
    private event_service: EventService,
    private question_service: QuestionService,
  ) {
    super();
    this.init(
      program_service,
      reservation_service,
      check_1_service,
      price_category_service,
      event_service,
      question_service,
    );
  }

  protected onReady(): void {
    this.subscriptions.push(
      // set all existing & allowed pages
      this.init_pages().subscribe(() => this.set_ready())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private init_pages() {
    // ensure running once -> cancel if prev run
    if (this.updater_running) {
      return;
    }
    this.updater_running = true;

    let existing_pages: BookingStep[];
    let allowed_pages: BookingStep[];

    return combineLatest([
      // groups exists
      this.program_service.get_current_program$(),

      // price cat & questions exist
      // groups, questions, price cat & confirm allowed
      this.reservation_service.get_reservations$(),

      // re-evaluate on question/answer changes
      this.question_service.questions_changed$()
    ]).pipe(
      filter(([_, reservations]) => reservations !== undefined),
      tap(() => {
        existing_pages = [BookingStep.PRIO];
        allowed_pages = [BookingStep.PRIO];
      }),

      // existing pages
      map(([curr_program, reservations]) => {
        // collect pages
        if (this.groups_exist(curr_program)) {
          existing_pages.push(BookingStep.GROUPS);
        }
        if (this.program_questions(reservations).length) {
          existing_pages.push(BookingStep.QUESTIONS);
        }
        if (this.price_categories_exist(reservations)) {
          existing_pages.push(BookingStep.PRICE_CATEGORY);
        }
        existing_pages.push(BookingStep.CONFIRM);

        this.existing_pages$.next(existing_pages);

        const prio_done = this.check_1_service.is_next_booking_step_allowed(
          reservations,
          curr_program
        );
        return [reservations, prio_done];
      }),

      filter((vals) => vals.every((val) => val !== undefined)),
      map(([reservations, prio_done]) => {
        reservations = reservations as Reservation[];

        if (!prio_done || this.confirm_done(reservations)) {
          return;
        }
        allowed_pages.push(BookingStep.GROUPS);

        if (
          !this.groups_done(reservations) &&
          existing_pages.includes(BookingStep.GROUPS)
        ) {
          return;
        }
        allowed_pages.push(BookingStep.QUESTIONS);

        if (
          !this.questions_done(reservations) &&
          existing_pages.includes(BookingStep.QUESTIONS)
        ) {
          return;
        }
        allowed_pages.push(BookingStep.PRICE_CATEGORY, BookingStep.CONFIRM);
        return;
      }),
      tap(() => {
        allowed_pages = allowed_pages.filter((allowed_page) =>
          existing_pages.includes(allowed_page)
        );
        this.allowed_pages$.next(allowed_pages);
      })
    );
  }

  public get_existing_pages$(): Observable<BookingStep[]> {
    return this.existing_pages$.asObservable();
  }
  public get_existing_pages(): BookingStep[] {
    return this.existing_pages$.getValue();
  }

  /**
   * Get all allowed pages in shopping cart.
   * Attention: is initially undefined to distinguish a true empty array
   * from the initial state without any information known about the pages.
   */
  public get_allowed_pages$(): Observable<BookingStep[]> {
    return this.allowed_pages$.asObservable();
  }
  /**
   * Get all allowed pages in shopping cart.
   * Attention: is initially undefined to distinguish a true empty array
   * from the initial state without any information known about the pages.
   */
  public get_allowed_pages(): BookingStep[] {
    return this.allowed_pages$.getValue();
  }

  /**
   * determines which booking step is on the line next.
   * @param current_page_name current active shown page
   */
  public get_next_page(current_page_name: BookingStep): BookingStep {
    const allowed_pages = this.get_allowed_pages();
    const index = (allowed_pages || []).indexOf(current_page_name);

    if (index === -1) {
      return BookingStep.PRIO;
    }
    if (index >= allowed_pages.length - 1) {
      return BookingStep.CONFIRM;
    }
    return allowed_pages[index + 1];
  }

  /**********************************/
  /********** EXIST-CHECKS **********/
  /**********************************/

  private groups_exist(program: Program): boolean {
    return (
      program?.is_group_registration_enabled &&
      program.distribution_type !== DistributionType.INSTANT
    );
  }

  private price_categories_exist(reservations: Reservation[]): boolean {
    const registrations = reservations
      .filter((reservation) => reservation.registrations)
      .reduce(
        (regs, reservation) => [...regs, ...reservation.registrations],
        [] as Registration[]
      );

    return (
      this.config_service.config.booking_settings.ask_for_price_categories &&
      registrations.some(
        (registration) =>
          this.price_category_service.get_visible_price_categories(
            registration.event_id
          ).length > 1 // Normalpreis is included in categories, so test for length > 1 to have some price_categories to choose from
      )
    );
  }

  /** get all questions of current program that do not have a default answer set in config */
  private program_questions(
    reservations: Reservation[]
  ): Question[] {
    if (!reservations) {
      return [];
    }

    const questions: Question[] = [];
    for (const reservation of reservations) {
      const person: Person = { role: reservation.type, pk: reservation.cache.other_participant || reservation.cache.child_obj };

      for (const registration of reservation.registrations) {
        const event: Event = this.event_service.get_event_by_id(
          registration.event_id
        );

        // add questions of registration
        const reg_questions = this.question_service.get_booking_questions(person, event) || [];
        questions.push(...reg_questions);
      }
    }
    return questions;
  }

  /*********************************/
  /********** DONE-CHECKS **********/
  /*********************************/

  // groups
  private groups_done(reservations: Reservation[]): boolean {
    return reservations?.every((reservation) =>
      reservation.registrations.every(
        (registration) => registration.zi.groups_done
      )
    );
  }

  // confirm
  private confirm_done(reservations: Reservation[]): boolean {
    return reservations?.length === 0;
  }

  // questions
  private questions_done(reservations: Reservation[]): boolean {
    // check if all valid
    return this.program_questions(reservations).every((q) => this.question_service.is_valid(q));
  }
}
