import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Config } from "../interfaces/config";
import { Registration, Reservation } from "../models/booking/reservation.interface";
import { SelectedPriceCatergoryDefault } from "../models/booking/selected_price_category_default.enum";
import { Event } from "../models/event/event.interface";
import { AccessCode } from "../models/program/program-extras.interface";
import { PriceCategory } from "../models/registration/price-category.interface";

import { AccessCodeService } from "./access-code.service";
import { ConfigService } from "./config.service";
import { EventService } from "./event.service";
import { FamilyCard, FamilyCardService } from "./family-card.service";

export enum PriceCategoryMode {
  /** is an allowed category */
  ALLOWED = "",
  /** ALLOWED + should be displayed to the user when choosing between categories */
  DISPLAY = "display",
  /** ALLOWED + should be selected by default */
  SELECT = "set_selected",
}

@Injectable({
  providedIn: "root",
})
export class PriceCategoryService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  private config_data: Config;
  private access_codes: AccessCode[] = []; // AccessCodes of the user in zi_backend
  private family_cards: { [child_id: number]: FamilyCard } = {};

  constructor(
    private config_service: ConfigService,
    private event_service: EventService,
    private access_code_service: AccessCodeService,
    private family_card_service: FamilyCardService
  ) {
    super();
    this.config_data = this.config_service.config;
    this.init(
      event_service,
      access_code_service,
      family_card_service
    );
  }

  protected onReady(): void {

    this.subscriptions.push(
      this.family_card_service.get_family_cards$().subscribe(cards => this.family_cards = cards),
      this.access_code_service.get_access_codes$().subscribe(codes => this.access_codes = codes)
    );

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  check(
    price_category: PriceCategory,
    family_card_valid: boolean,
    program_id: number,
    display: PriceCategoryMode,
    display_normalpreis: boolean,
  ): boolean {
    const category = this.config_data.category_access.find(
      (value) => value.category === price_category.name
    );

    if (price_category.name === "Normalpreis" && !display_normalpreis) {
      return false;
    }

    if (display === PriceCategoryMode.DISPLAY) {
      return !category || category.display;
    }
    if (display === PriceCategoryMode.SELECT) {
      return category?.set_selected;
    }

    // case display === PriceCategoryMode.ALLOWED
    const access_code_exists = this.access_codes.some(
      // Test if price_category.name is a substring of the users AccessCode name.
      // Returns true if user's AccessCode is called "GWA/KiEZe(Landkreis Blibla)" and price_category is called only "GWA/KiEZe"
      // I test for substrings and not exact because the AccessCode names in our backend were messed up (saved category+ description instead of only category)
      (code) => code.name.includes(price_category.name)
    );

    const category_not_specified_in_config = !category;
    const category_is_applicable_for_program = !category?.program_id || category.program_id === program_id;
    const category_is_valid_to_choose =
      (!category.needs_familycard || family_card_valid) &&
      (!category.needs_access_key || access_code_exists);

    return category_not_specified_in_config ||
      (
        price_category.has_free_places &&
        category_is_applicable_for_program &&
        category_is_valid_to_choose
      );
  }

  /** this includes price_categories of the event and its default price */
  public get_visible_price_categories(event_id: number): PriceCategory[] {
    if (!event_id) {
      return;
    }

    const event = this.event_service.get_event_by_id(event_id);
    if (!event) { return []; }

    const program_id = this.event_service.get_program_on_event(event).program_id;
    return [
      this.get_default_price_as_price_category(event.price),
      ...event.price_categories
    ].filter((cat) => this.check(cat, false, program_id, PriceCategoryMode.DISPLAY, event.display_normalpreis));
  }

  /**
   * anhand der config wird die Preiskategorie gesetzt
   * und errechne den Preis, setze die price_descsription
   */
  // TODO: do at central point somewhere? currently called on save of reservation in ReservationService
  public get_price_categories(reservation: Reservation): {[event_id: number]: PriceCategory} {
    if (!reservation.registrations?.length) { return {}; }

    const access_codes = this.access_code_service.get_access_codes(); // Liefert die AccessCodes aus dem zi_backend zurück
    const pk = reservation.cache.other_participant || reservation.cache.child_obj;

    const familycard_valid = !!this.family_cards[pk]?.family_card;
    return reservation.registrations.reduce((acc, registration) => {
      acc[registration.event_id] = this.get_price_category(reservation, registration, familycard_valid, access_codes);
      return acc;
    }, {});
  }

  private get_default_price_as_price_category(price: number): PriceCategory {
    return {
      id: 0,
      price,
      has_free_places: true,

      category: 'Normalpreis',
      name: 'Normalpreis',
      is_archived: false,
      is_waiting_list_full: false,
      price_difference: 0,
      quantity: undefined,
      waiting_list: undefined,
    }
  }

  /**
  * Diese Funktion sammelt für den Nutzer gültige Preiskategorien
  * Dabei werden Familienkarte, AccessCodes und andere in Betracht gezogen
  * Der Rückgabewert ist ein Array aus PriceCategories
  */
  private get_price_category(reservation: Reservation, registration: Registration, familycard_valid: boolean, access_codes: AccessCode[]): PriceCategory {
    const event: Event = this.event_service.get_event_by_id(
      registration.event_id
    );
    if (!event) { return null; }

    if (this.config_data.default_selection_of_price_category == SelectedPriceCatergoryDefault.NORMAL_PRICE) {
      return this.get_default_price_as_price_category(event.price);
    }
    
    const all_categories = [
      this.get_default_price_as_price_category(event.price),
      ...event.price_categories
    ];
    const categories: PriceCategory[] = [];

    // handle familycard
    const familycard_category_names: string[] = this.config_data.category_access
          .filter(config_price_category => config_price_category.needs_familycard)
          .map(config_price_category => config_price_category.category);

    const familycard_categories_of_event = all_categories
      .filter(event_price_category => familycard_category_names.includes(event_price_category.category))
      .filter(event_price_category => this.check(
        event_price_category,
        familycard_valid, // familycard
        this.event_service.get_program_on_event(event).program_id,
        PriceCategoryMode.ALLOWED,
        event.display_normalpreis
      ));
    categories.push(...familycard_categories_of_event);



    // handle zip-codes
    const zip_code = parseInt(reservation.participant.zip_code);
    const zip_category_names: string[] = this.config_data.category_access
      .filter(config_price_category =>
          config_price_category.needs_zip_code &&              // need zip_code
          config_price_category.zip_codes?.includes(zip_code)  // reservation has fitting zip_code
      )
      .map(config_price_category => config_price_category.category);

    const zip_categories_of_event = all_categories
      .filter(event_price_category => zip_category_names.includes(event_price_category.category));
    categories.push(...zip_categories_of_event);



    // handle accesscodes
    const access_code_names = access_codes.map(ac => ac.name); // Namen der ACs, welche der User im backend besitzt

    // Filter die Preiskategorien aus config_data.category_access danach,
    // ob der Nutzer im zi_backend einen AccessCode besitzt in dessen Name
    // der "category" string der Preiskategorie vorkommt.
    // Wenn der Nutzer beispielsweise einen AccessCode mit dem Namen "GWA/KiEZe(Landkreis Bliblablub)"
    // besitzt und es in dem Array category_access in der base.json eine Preiskategorie mit dem Namen
    // "GWA/KiEZe" gibt, dann enthält die variable accesscode_category_names die Preiskategorie "GWA/KiEZe".
    const accesscode_category_names = this.config_data.category_access
      .filter(config_price_category => config_price_category.needs_access_key)
      .filter(config_price_category => access_code_names
        .some(access_code_name  => access_code_name.includes(config_price_category.category)) // Returns true if config_data.category_access[i].category (e.g. "GWA/KiEZe") is a substring of the users access_code_name (e.g. "GWA/KiEZe(Landkreis Bliblablub)")
      )
      .map(config_price_category => config_price_category.category); // Map AccessCode names into an array

      // 1. der Nutzer im backend besitzt
      // 2. die in der config in category_access aufgeführt sind
      // An dieser Stelle muss der AccessCode name des Nutzers (aus dem zi_backend) mit AC.category gematched werden
      // AC.category (e.g. GWA/KIEZ) muss ein Substring des AC des users sein (z.b. GWA/KIEZ (Landkreis blabla))
    const accesscode_categories = all_categories
      .filter(event_price_category => accesscode_category_names.includes(event_price_category.category))
      .filter(event_price_category => this.check(
        event_price_category,
        false,
        this.event_service.get_program_on_event(event).program_id,
        PriceCategoryMode.ALLOWED,
        event.display_normalpreis
      ));

    categories.push(...accesscode_categories);


    // handle all others
    // Hier werden unter folgender Voraussetzung alle Preiskategorien einer Veranstaltung extrahiert und zu categories hinzugefügt
      // Der Name der Preiskategorie des events (event.price_category.category) kommt in keiner Preiskategorie in category_access vor ODER jede Voraussetzung ist 'false' (-> wird nicht angezeigt, kann aber gewählt werden).
      // Es könnte sein dass dieses feature von Kunden so nicht gewollt ist.
      // Es führt nämlich dazu, dass für den Nutzer IMMER die günstigste Preiskategorie ausgewählt wird, wenn diese nicht in category_access aufgeführt wird.
      // Wenn aber für eine Preiskategorie eigentlich bestimmt Zugangsvoraussetzungen nötig sind (z.B. Besitz einer Familie oder eines Zugangscodes)
      // dann ist dieses Verhalten vielleicht nicht gewünscht.
      // Dann kann man es aber in der config hinterlegen.
    const category_names = this.config_data.category_access.map(cat => cat.category); // Map all category names of category_access objects to an array
    const ignored_category_names = this.config_data.category_access.filter(cat => 
        !cat.needs_access_key && !cat.needs_familycard && !cat.needs_zip_code
      ).map(cat => cat.category); // Map all category names of category_access objects to an array

    categories.push(
      ...all_categories.filter(event_price_category => 
        !category_names.some(category_name => event_price_category.category.includes(category_name)) ||
        ignored_category_names.some(category_name => event_price_category.category.includes(category_name))
      )
    );


    // use only those with capacity left; choose cheapest of the remaining -> order asc by price
    const price_categories = categories.filter(cat => !cat.is_archived && (cat.has_free_places || !cat.is_waiting_list_full));
    if (!price_categories.length) { return undefined; }

    switch (this.config_data.default_selection_of_price_category) {
      case SelectedPriceCatergoryDefault.CHEAPEST:
        return price_categories.sort((a, b) => a.price - b.price)[0];
        
      case SelectedPriceCatergoryDefault.CUSTOM:
        for (const cat_name of this.config_data.default_price_category_name_order) {
          const cat = price_categories.find(category => category.name === cat_name);
          if (cat) { return cat; }
        }

      // Info: case SelectedPriceCatergoryDefault.NORMAL_PRICE:  has been dealt with previously in this function

      default:
        return price_categories[0];

    };
  }
}
